import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useScrollCoordinates } from '../../hooks/useScrollCoordinates';
import { useWindowSize } from '../../hooks/useWindowSize';
import { theme } from '../../domain/theme';
import { getOverflowClipping } from '../../domain/utils/domUtils';
import { Actionable } from '../responsive/atoms/Actionable';
import { IconChevronLeft } from '../Icon/IconChevronLeft';
import { IconChevronRight } from '../Icon/IconChevronRight';
import { Fade } from '../responsive/atoms/Fade';
import VideoCarouselCard from './VideoCarouselCard';

const styles = {
  links: css`
    white-space: nowrap;
    width: 100%;
  `,
  arrows: css`
    position: absolute;
    width: 100%;
    top: 34%;
    ${theme.mq.small_desktop} {
      top: 26%;
    }
    ${theme.mq.large_desktop} {
      top: 30%;
    }
  `,
  active: css`
    border-bottom: 4px solid #fc0;
    order: -1;
  `,
  prev: css`
    button > svg {
      /*       margin: 0;
      ${theme.mq.small_desktop} {
        margin: 13px 0 13px 7px;
      } */
    }
  `,
  next: css`
    right: 0;
    button > svg {
      float: right;
      margin: 0;
      /*       ${theme.mq.small_desktop} {
        margin: 13px 7px 13px 0;
      } */
    }
  `
};

export const VideoCarousel = ({
  items,
  backgroundColor,
  onClick,
  activeIndex,
  isAboveTheFold
}) => {
  const fontColor = backgroundColor === 'white' ? 'black' : 'white';
  const scrollContainerRef = useRef(null);
  const [overflowState, setOverflowState] = useState({
    left: false,
    right: false
  });

  const updateOverflowState = () => {
    const overflowClipping = getOverflowClipping(scrollContainerRef.current);
    setOverflowState({
      left: overflowClipping.left,
      right: overflowClipping.right
    });
  };
  const { x: elementInnerScrollXPosition } = useScrollCoordinates({
    ref: scrollContainerRef,
    debounceWait: 100
  });
  const { width: windowWidth } = useWindowSize({
    triggerOnMount: false,
    debounceWait: 100
  });

  useEffect(() => {
    updateOverflowState();
  }, [elementInnerScrollXPosition, windowWidth]);

  const scrollCarouselToTheRight = () => {
    scrollContainerRef.current.scrollLeft +=
      scrollContainerRef.current.clientWidth;
  };
  const scrollCarouselToTheLeft = () => {
    scrollContainerRef.current.scrollLeft -=
      scrollContainerRef.current.clientWidth;
  };

  const carouselStyles = {
    mainContainer: css`
      background-color: ${backgroundColor};
      position: relative;
    `,
    carouselWrapper: css`
      display: block;
      color: ${fontColor};
      position: relative;
      &:before {
        background-color: ${backgroundColor};
        background-size: cover;
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
    `,
    carouselContainer: css`
      margin: ${theme.spacing.parse('$xs 0')};
      position: relative;
      /* padding-bottom: ${theme.spacing('xs')}; */
      max-width: 100%;
      ${theme.mq.tablet} {
        margin: ${theme.spacing.parse('$base 0')};
      }
      /*
      ${theme.mq.small_desktop} {
        padding-bottom: ${theme.spacing('xl2')};
        max-width: 1130px;
      }
      ${theme.mq.large_desktop} {
        max-width: 730px;
      } */
    `,
    scrollContainer: css`
      overflow-x: scroll;
      scroll-behavior: smooth;
      position: relative;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      margin: ${theme.spacing.parse('$xs $s')};
      max-width: 100%;
      ${theme.mq.small_desktop} {
        margin: ${theme.spacing('base')};
      }
    `,
    icons: css`
      display: none;
      ${theme.mq.small_desktop} {
        display: block;
        color: ${fontColor};
        position: absolute;
        top: 0;
        width: 60px;
        height: 44px;
        font-size: ${theme.font.size('s')};
        line-height: ${theme.line.height('base')};
      }
    `
  };
  return (
    <div css={carouselStyles.mainContainer}>
      <div css={carouselStyles.carouselWrapper}>
        <div css={carouselStyles.carouselContainer}>
          <div css={styles.arrows}>
            <Fade
              show={overflowState.left}
              position="relative"
              zIndex="1"
              duration={0.15}
            >
              <div css={[carouselStyles.icons, styles.prev]}>
                <Actionable onClick={scrollCarouselToTheLeft}>
                  <IconChevronLeft size={20} display="block" />
                </Actionable>
              </div>
            </Fade>
          </div>

          <div css={styles.arrows}>
            <Fade
              show={overflowState.right}
              position="relative"
              zIndex="1"
              duration={0.15}
            >
              <div css={[carouselStyles.icons, styles.next]}>
                <Actionable onClick={scrollCarouselToTheRight}>
                  <IconChevronRight size={20} display="block" />
                </Actionable>
              </div>
            </Fade>
          </div>

          <div ref={scrollContainerRef} css={carouselStyles.scrollContainer}>
            <div css={styles.links}>
              {items.map((card, index) => {
                return (
                  <VideoCarouselCard
                    index={index}
                    key={card.entity.id}
                    data={card.entity}
                    fontColor={fontColor}
                    isAboveTheFold={isAboveTheFold}
                    onClick={onClick}
                    active={index === activeIndex}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoCarousel.defaultProps = {
  backgroundColor: 'black'
};

VideoCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  backgroundColor: PropTypes.string,
  isAboveTheFold: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired
};
