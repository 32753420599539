import React, { useState, useEffect } from 'react';
import { conf } from '../domain/services/configService';
import { eventService } from '../domain/services/eventService';
import { addScript } from '../domain/utils/domUtils';

export const getSDKScriptUrl = () => {
  return conf.isStage
    ? 'https://player-stage.techops.disn.io/current/build/app.js'
    : `https://player.techops.disn.io/current/build/app.js`;
};

const areAllScriptsLoaded = () => 'SDK' in window;

const initialState = {
  sdk: undefined,
  sdkHelper: undefined
};

export const WithVideoSDK = Component => {
  return props => {
    const [state, setState] = useState(initialState);

    const onScriptLoad = () => {
      if (areAllScriptsLoaded()) {
        setState({ sdk: window.SDK, sdkHelper: window.SDKHelper });
      }
    };

    const loadScripts = () => {
      addScript(getSDKScriptUrl(), { async: false, callback: onScriptLoad });
    };

    const checkForScriptPresence = () => {
      if (areAllScriptsLoaded()) {
        onScriptLoad();
      } else {
        loadScripts();
      }
    };

    useEffect(() => {
      eventService.addEventListener(
        'OneTrustInteractionDone',
        checkForScriptPresence,
        {
          once: true
        }
      );
    }, []);

    return state.sdk ? (
      <Component
        sdk={state.sdk}
        sdkHelper={state.sdkHelper}
        // TODO FIXME remove template after migrating all players
        template={conf.MpxVideoPlayerTemplate}
        {...props}
      />
    ) : null;
  };
};
